<template>
    <div
        class="flex flex-col scrollToElement relative"
        :class="['form-field', {
            'form-field--invalid': isError,
            'form-field--consent': getCascadedFieldType(fieldData)?.customTemplate === 'consent',
            'bg-gray-100 pl-5 rounded-xl': parentData.form_entity_type != 'BLOCK',
            'consent-form-field-responsive': previewForm
        }]"
    >
        <!-- Dont show label for toggle  -->
        <!-- <div class="scroll-head absolute h-2 w-full"></div> -->
        <div class="justify-between">
            <template v-for="(field,index) in fieldData.cascading_fields">
                <FormField
                    :key="field.field_fe_id || field.field_id"
                    :ref="field"
                    :fieldData="field"
                    :userData="userData"
                    :parentData="parentData"
                    :path="[
                        ...path,
                        { field: 'field_fe_id', value: field.field_fe_id },
                    ]"
                    :validations="getValidation(index)"
                    :previewMode="previewMode"
                    :previewForm="previewForm"
                    :linkedBlock="linkedBlock"
                    :disabled="!!isDisabled ||  (!field.editable ) || disabled || isAddReadOnly"
                    :hasSignature="hasSignature"
                    :class="{inputLabel : previewForm}"
                    :formAlreadySubmitted="formAlreadySubmitted"
                    :isCopyBlock="isCopyBlock"
                    :checkBoxId="checkBoxId"
                    :isBlockDeleted="isBlockDeleted"
                    :hidden="hidden"
                    @checkCheckboxId="checkCheckboxId"
                    @checkBoxData="checkBoxData"
                    @nextSection="nextSection"
                    @addTimeline="addTimeline"
                    @removeTimeline="removeTimeline"
                />
            </template>
        </div>
    </div>
</template>

<script>
// TODO remove unnecessary code

import { EventBus } from "@/main.js";
import store from "../store";
import {
    getFieldById,
    getEntityByKeyList,
    showCascaded,
} from "../utils";
import fieldTypes from "../utils/field-types";
const FormField = () => import("./formField.vue");

export default {
    name:"cascading-field",
    components: {
        FormField,
    },
    props: {
        parentData: {
            type: Object,
            require: true,
        },
        // receeives options data
        fieldData: {
            type: Object,
            require: true,
        },
        // optionData: { // TODO uncomment and handle properly
        //     type: Object,
        //     require: true,
        // },
        path: Array,
        // Used to determin if field can be copied. Is false when field is multi but can't be copied anymore:
        isMulti: {
            type: Boolean,
            default: false,
        },
        validations: {
            type: Object,
            default: () => null,
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        selectedFieldValue: {
            type: String,
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        linkedBlock: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object,
            default:()=> {}
        },
        hasSignature:{
            type: Boolean
        },
        notInsideBlock:{
            type: Boolean
        },
        formAlreadySubmitted: {
            type: Boolean
        },
        isCopyBlock: {
            type: Boolean
        },
        checkBoxId: {
            type: String
        },
        isBlockDeleted: {
            type: Boolean,
        },
        isAddReadOnly:{
            type: Boolean,
        },
        hidden: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            fieldTypes,
            customTemplates: ['consent', 'label'],
            store,
            windowWidth: 0,
            windowHeight: 0,
            dateRangeOptions:[],
            value:"",
            showCascaded,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();
        this.value = this.fieldData?.value
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    created() {
        // check if field uses value of another field:
        if (this.usedFromField) this.fieldData.value = this.usedFromFieldValue;
        // or if there is the same field in linked block:
        else if (this.linkedBlockField) this.fieldData.value = this.linkedBlockFieldValue;
        // check if field is copy and has related field and set additional relation configs:
        if (this.isRelated) {
            const compareParams = {
                // search by original field_id prop:
                field_id: this.fieldData.condition.field_id,
                // TODO when we copy field we have different fields with same field_id. Should be handled otherwise the first match will be used
            }
            // if field is copy search for field with the same copy id:
            if (this.fieldData.copy_id) compareParams.copy_id = this.fieldData.copy_id;
            // or search for field within the same block/section:
            else compareParams.pathParentString = this.fieldData.pathParentString;
            const relatedField = getEntityByKeyList(this.sections_data, compareParams);
            this.$set(this.dependencies[this.fieldData.pathString], 'related_field', relatedField);
            this.$set(this.dependencies[this.fieldData.pathString], 'hidden', !this.isShown);
        }
        // if field is used for gap validation create a gap group in store:
        if (this.fieldData.gap_validation) {
            if (!this.gapGroup) this.gapGroup = {
                block_id: this.parentData.block_fe_id,
                field_id: this.fieldData.field_id,
                timeline: [],
            }
            if (!this.gapGroup.timeline.some(el => el.field?.field_id === this.fieldData?.field_id)) {
                this.gapGroup.timeline.push({
                    field: this.fieldData,
                    block: this.parentData,
                })
            }
        }
    },
    watch: {
        isShown(val) {
            if (this.isRelated) this.$set(this.dependencies[this.fieldData.pathString], 'hidden', !val);
            // reset field value if it was removed from the form:
            // if (!val) this.fieldValue = null;
            this.value = this.fieldData?.value ? this.fieldData?.value : this.value
            this.fieldValue = val ? this.value : null;
        },
        usedFromFieldValue(val) {
            this.fieldValue = val;
        },
        linkedBlockFieldValue(val) {
            this.fieldValue = val;
        },
    },
    computed: {
        isFieldMulti() {
            return this.fieldData.multi;
        },
        showLabel(){
            return this.getFieldType !== 'toggle' && this.getFieldType !== 'label' && this.getFieldType !== 'linked button'
        },
        sections_data() {
            return store.list;
        },
        dependencies() {
            return store.dependencies;
        },
        // * Here, the related field is the field-influencer which value define if the current field is shown
        isRelated() {
            return Boolean((this.fieldData.pathString in this.dependencies) && this.fieldData.condition);
        },
        dependencyData() {
            return this.dependencies[this.fieldData.pathString];
        },
        // related field is a field which value determine if the current field is shown:
        relatedField() {
            return this.dependencyData?.related_field;
        },
        isRelatedShown() {
            // if field isn't dependent or related field isn't dependent we don't need to check this:
            if (!this.isRelated || !this.dependencies[this.relatedField.pathString]) return true;
            return !this.dependencies[this.relatedField.pathString].hidden;
        },
        isShown() {
            if (!this.fieldData.visible) return false;
            if (this.isRelated) {
                return this.relatedField?.value === this.dependencyData.condition.dependence_value && this.isRelatedShown;
            } else return true;
        },
        // isUsedFrom define if the value of the current field should be the same with another field:
        isUsedFrom() {
            return !!this.fieldData.use_value_from;
        },
        isDisabled() {
            return (this.isUsedFrom && this.fieldValue) || this.linkedBlockField || this.previewForm;
        },
        usedFromField() {
            if (!this.isUsedFrom) return null;
            // search by field_fe_id because only original field has the same field_id and field_fe_id
            return getFieldById(this.sections_data, this.fieldData.use_value_from, 'field_fe_id');
        },
        usedFromFieldValue() {
            return this.usedFromField?.value;
        },
        // we also use fields from linked block as the used_from field because we use theirs values in the same way:
        linkedBlockField() {
            if (!this.linkedBlock) return null;
            return getEntityByKeyList(this.linkedBlock.entities, { field_internal_name: this.fieldData.field_internal_name, index: this.fieldData.index });
        },
        linkedBlockFieldValue() {
            return this.linkedBlockField?.value;
        },
        fieldValue: { // TODO remove?
            get() {
                return this.fieldData.value;
            },
            set(value,field) {
                console.log(field)
                if (this.previewForm) return;
                // EventBus.$emit("changeFieldValue", { path: this.path, value, type: this. getFieldBaseType || this.getFieldType });
                // update field using refference to the field in sections_data:
                this.fieldData.value = value;
                this.fieldData.error_message = '';
                // update used_from field if exists:
                if (this.usedFromField) {
                    this.usedFromField.value = value;
                    this.usedFromField.error_message = '';
                }
            }
        },
        getFieldType() {
            return this.fieldData?.field_type?.toLowerCase();
        },
        getFieldBaseType() {
            return this.fieldData?.field_base_type?.toLowerCase();
        },
        getHelpText() {
            return this.fieldData?.description_text ?? "";
        },
        // getComponentField() {
            //     return this.getFieldType + '-field'
        // },
        getFieldConfig() {
            return this.fieldTypes[this.getFieldType] || this.fieldTypes[this.getFieldBaseType];
        },
        isError() {
            return this.validations?.$error || !!this.fieldData.error_message;
        },
        // isCopy() {
        //     // fields in copied block have new field_fe_id that aren't equal to field_id.
        //     // return (this.isFieldMulti && this.fieldData.field_id !== this.fieldData.field_fe_id) || this.fieldData.is_copy;
        //     // So there is a new logic for copy determination:
        //     // find a first field with the same field_id and compare with current:
        //     const firstField = getFieldById(this.parentData.entities, this.fieldData.field_id);
        //     return firstField.field_fe_id !== this.fieldData.field_fe_id;
        // },
        // isSavedCopy() {
        //     // is_copy is a temporary frontend property
        //     return this.isCopy && !this.fieldData.is_copy;
        // },
        gapGroup: {
            get() {
                return store.gap_values[this.parentData.block_id] || null;
            },
            set(val) {
                this.$set(store.gap_values, this.parentData.block_id, val);
            },
        },
        currentValidations() {
            return this.validations?.entities || this.validations;
        },
        uppercase()
        {
            return this.fieldData.cascading_fields.some((entity) => entity.field_internal_name === 'ni_number')  
        },
    },
    methods: {
        getCascadedFieldType(field) {
            if(field) {
                let cascadedField = this.fieldTypes[field?.field_type?.toLowerCase()] || this.fieldTypes[field?.field_base_type?.toLowerCase()];
                return cascadedField
            }
            else return ""
            
        },
        nextSection() {
            EventBus.$emit("nextSection");
        },
        addTimeline() {
            this.$emit('addTimeline');  
        },
        removeTimeline() {
          this.$emit('removeTimeline');  
        },
        handleWindowResize() {
            this.getWindowSize();
        },
        checkCheckboxId(val){
            this.$emit('checkCheckboxId',val);
        },
        getWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        checkBoxData(val) {
            this.$emit('checkBoxData', val);
        },
        getValidation(index) {
            return this.currentValidations?.cascading_fields?.[index]
        },
        getCascadingValidations(field, index1, index2) {
            if (!this.validations) return null
            return this.currentValidations?.cascading_fields?.[index1]?.options?.[index2] || {}
        },
},
};
</script>

<style lang="scss">
.form-field {

&--consent{
    template{
            .template__label{
                overflow:break-word;
                word-wrap:break-word;
                hyphens:auto !important;

            }

}
}
.form-label, .consent-content {
    a {
        @apply text-primary;
        text-decoration: underline;
        cursor: pointer;
    }
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1.00em;
        }
        h5 {
            font-size: 0.83em;
        }
        h6 {
            font-size: 0.67em;
        }
    }
    ul, ol {
        padding-left: 3em;
    }
    ul {
        li {
            list-style: disc;
        }
    }
    ol {
        li {
            list-style: auto;
        }
    }
}

   


</style>
<style lang="scss" scoped>
.consentViewButton{
    background: none !important;
}
.consentError{
    border-color:rgba(220,38,38,var(--tw-border-opacity)) !important;
}

// .form-field{
//     width:100% !important;
//     margin: auto;
// }

.form-field {
    &--consent {
        .template {
            width:100%;
            .show-button{
                opacity: 0 !important;
                pointer-events: none;
            }

            &:hover {
                .show-button{
                    opacity: 1 !important;
                    pointer-events: initial;
                }
                .template__label {
                    // filter: blur(2px) !important;
                }
            }
        }
    }
}


.consent-button{
    cursor:pointer;
}

// .fade-bottom {
//     background: linear-gradient(to top, #fff 20%, transparent);
// }
::v-deep {
    input, textarea, select {
        &:not(.filepond--browser) {
            &:disabled {
                @apply opacity-80 text-gray-600;
                cursor: not-allowed;
            }
        }
    }
   
}
.neo-form-builder__info {
    min-width: 50px;
    margin: 0;
    color: black !important;
}

label {
    word-break: break-word;
}

.consentViewButton{
    margin:auto;
    text-align:center;
    & span{
      margin-top:-5%;
    }
    & span > span{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: -2px;
    }
}

 .mobileConsentViewButton .eye-icon{
    margin-top:1.5% !important;
}


@media (min-width: 300px) and (max-width: 500px) and (max-height:1400px){
    .mobileConsentViewButton{
        display: flex;
        //line-height: 20px;
        width: 100%;
        font-size: 15px;
        margin:0 auto auto auto;
        background-color:white;

    }
    

    .neo-form-builder__info {
        color: black !important;
        min-width: 50px;
        margin: 0;
         color: black !important;
    }
    
    label{
        width:100% !important;
    }

    
    .consent-field-block{
    margin:10px auto auto -1.7% !important;
    }
   
}

@media (min-width: 300px) and (max-width: 420px) and (max-height:1400px){
    .consent-field-block{
    margin:10px auto auto -2% !important;
    }
}
@media (max-width:1080px){
    .scrollToElement{
        // -webkit-scroll-margin-top: 4rem;
        // scroll-margin-top: 4rem;
    }
    .scroll-head{
        top: -4rem;
    }
}
@media (max-width:940px) and (max-height:1400px){
     
    .form-field{
        margin-bottom: 25px !important;
    }
.form-field {
    &--consent {
        .template {
            .show-button {
                opacity:1 !important;
                pointer-events: none;
            }
            &:hover {
                .show-button {
                    opacity: 1;
                    pointer-events: initial;
                }
                .template__label {
                    filter: none !important;
                }
            }
        }
    }
}



 .form-field .firstbtn{
       margin-top:0.1%;
    }
    .consentViewButton .eye-icon{
        margin-top:0.8%;
    }

.mobileConsentViewButton,.consentViewButton {
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto auto !important;
        background-color:white;
        color:#0D6AD5;
    }

.consentViewButton{
    padding-top:6% !important;
}

.consent-button button{
   position:relative;
   display:flex;
   margin:auto;
}

.consent-button{
    height:20px !important;
    width:100%;
    padding:0px;
     margin: 4% auto 4% auto;
}

.consentBlock{
    display:flex;
    flex-direction:column;
    align-items:center !important;
    justify-content:center;
    margin:auto;
    cursor:pointer;
     padding:0.5rem;
    // padding: 0.5rem;
}


.consent-field-block{
    padding:3px 8px 8px 8px !important;
    // margin:10px auto auto -1.3%;
    margin: auto !important;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
    border:1px solid #DBDDF1;
}

.view-consent{
    display:flex;
}
      
}

@media screen and (min-width:769px) and (max-width:940px) and (max-height:1440px){

.consent-field-block{
    padding:3px 8px 8px 8px !important;
    margin:10px auto auto -1%;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
     border:1px solid #DBDDF1;
}
      
}

@media screen and (min-width:941px){

.eye-icon {
    color:white !important;
    z-index:10;
} 
svg {
    color:white !important;
} 


.form-field{
&--consent {
        .template {
            .show-button {
                opacity:0 !important;
            }
                .show-button{
                    opacity: 1 !important
                
                }
                .template__label {
                    // filter:blur(2px) !important;
                }
            
        }
    }
} 



.consent-field-block{
    border:1px solid #DBDDF1;
    padding:3px 8px 8px 8px !important;
    margin:auto auto auto auto;
    align-items:center;
    width:100%;
    line-height:24px;
    overflow:break-word;
    word-wrap:break-word;
    hyphens:auto;

    &:hover{
        // filter:blur(2px) !important;
    }
}



.consent-button{
    display:flex;
    position:absolute;
    width:100%;
    //margin:5% 27% 5% 32% !important;
    justify-content:center;
    height: 2.8em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & button{
        cursor:pointer !important;
        width:260px;
    }
}



.consent-button button{
      height:2.9em;
      display:flex;
    margin:auto;
    & .eye-icon{
        color:white !important;
        position:absolute;
     }

    & span{
        margin-top:1%;
        & span{
            margin:auto auto auto 20px;
        }
    }
}

    .consent-button button{
    position: relative;
    display: flex;
    }
    .consent-button {
    height: 10px !important;
    width: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    margin: 16px auto;
    opacity: 1 !important;
    }
    .consentBlock {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-radius: 10px;
    }
    .consentViewButton{
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto 2px !important;
        background-color:white;
        color:#0D6AD5;
        padding: 0;
        height: auto !important;
        position: relative;
        background: none;
    }

    .consent-field-block{
        border: 1px solid #DBDDF1;
        padding: 6px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

    }
    .consent-button button span span{
        margin: auto auto auto 23px;
        width: 200px;
    }

.view-consent{
    display: flex;
    align-items: center;
    width: 145px;
    justify-content: space-between;
}
}

.landscape-visible{
        display: none !important;
    }

@media screen and (min-width: 600px) and (max-width: 942px) and (orientation: landscape){
    .landscape-visible{
        display: block !important;
    }
    .landscape-notVisible{
        display: none !important;
    }
    .consent-button{
        margin:2% auto !important;
    }
    .consentViewButton span{
        // margin-top: -5% !important;;
    }
    .consentViewButton{
        padding-top: 0 !important;
    }
    .consentViewButton>span{
        margin-top: 16px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px){
    .dynamicInputFields ,.formFieldFieldSet select, .formFieldFieldSet div{
        border-radius: 10px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 768px){
    .viewConsentBtn{
        height:20px;
    }
}

.newInput_add{
    display:none;
}
@media screen and (min-width: 360px) and (max-width: 487px){
    .consentViewButton{
        margin-top: 0% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 359px){
    .dynamicInputFields{
        width: 190px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 190px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 414px){
    .dynamicInputFields{
        width: 210px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: auto !important;
        margin: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 550px) and (max-width: 1080px) and (orientation: landscape) {
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px){
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
    
}

    
</style>