var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col scrollToElement relative",class:['form-field', {
        'form-field--invalid': _vm.isError,
        'form-field--consent': _vm.getCascadedFieldType(_vm.fieldData)?.customTemplate === 'consent',
        'bg-gray-100 pl-5 rounded-xl': _vm.parentData.form_entity_type != 'BLOCK',
        'consent-form-field-responsive': _vm.previewForm
    }]},[_c('div',{staticClass:"justify-between"},[_vm._l((_vm.fieldData.cascading_fields),function(field,index){return [_c('FormField',{key:field.field_fe_id || field.field_id,ref:field,refInFor:true,class:{inputLabel : _vm.previewForm},attrs:{"fieldData":field,"userData":_vm.userData,"parentData":_vm.parentData,"path":[
                    ..._vm.path,
                    { field: 'field_fe_id', value: field.field_fe_id },
                ],"validations":_vm.getValidation(index),"previewMode":_vm.previewMode,"previewForm":_vm.previewForm,"linkedBlock":_vm.linkedBlock,"disabled":!!_vm.isDisabled ||  (!field.editable ) || _vm.disabled || _vm.isAddReadOnly,"hasSignature":_vm.hasSignature,"formAlreadySubmitted":_vm.formAlreadySubmitted,"isCopyBlock":_vm.isCopyBlock,"checkBoxId":_vm.checkBoxId,"isBlockDeleted":_vm.isBlockDeleted,"hidden":_vm.hidden},on:{"checkCheckboxId":_vm.checkCheckboxId,"checkBoxData":_vm.checkBoxData,"nextSection":_vm.nextSection,"addTimeline":_vm.addTimeline,"removeTimeline":_vm.removeTimeline}})]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }