// const sections =

import FormBuilder from "./components/dynamicFormBuilder.vue";
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js";
import { /* get, set, */ cloneDeep } from "lodash";
// import { generateRandomString } from "@/utils/functions.js";
import { helpers, requiredIf } from "vuelidate/lib/validators";
// fakeData for testing:
// import { fakeData1, fakeData2 } from "./utils/fakeData.js";
import sections, { mutations } from "./store";
import fieldTypes from "./utils/field-types";
import {
    getEntityByKeyList,
    isJson,
    logValidationErrors,
    subtractMonths,
    formatDate,
    getFieldById,
    isObjectEmpty,
    removeLocalStorageItemsWithPrefix,
    isEquel,
    getCascadingFields,
    sectionStatusConfig,
} from "./utils";
import UserProfile from "./components/form-components/user-profile";
import PoweredByIcon from "@shared/assets/powered-by.svg";
import PrintIcon from "@shared/assets/print.svg";
import previewFormModal from "./components/previewFormModal.vue";
import Loader from "@shared/loader";
import Vue from "vue";
import Toast from "vue-toastification";
import {checkPermission} from "@shared/utils/functions"
import "vue-toastification/dist/index.css";
Vue.use(Toast);


import { uuid } from "vue-uuid";

const customValidators = {
    regex: (regexString) => (val) => new RegExp(regexString).test(val),
};

const formateDateToString= (date) =>{
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// Use it if need to control whether form was saved on the server side:
const formSavedErrorCodes = ["ERROR.GAP_VALIDATION"];

export default {
    name: "neo-form-builder",
    components: {
        FormBuilder,
        UserProfile,
        PoweredByIcon,
        PrintIcon,
        previewFormModal,
        Loader,
    },
    props: {
        app: String,
        data: {
            type: Object,
            default: () => ({})
        },
        saveSectionMethod: {
            type: Function,
        },
        // TODO remove when custom sections are ready:
        // savePersonalDetailsMethod: {
        //     type: Function,
        // },
        // removeBlockMethod: {
        //     type: Function,
        // },
        // removeFieldMethod: {
        //     type: Function,
        // },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false,
        },
        gapErrors: {
            type: Object,
            default: () => ({}),
        },
        userProfileView: {
            type: Boolean,
            default: false,
        },
        userInfo: {
            type: Object,
            default: () => ({}),
        },
        // TODO remove when custom sections are ready:
        // showWelcomeSection: {
        //     type: Boolean,
        //     default: false,
        // },
        // showPersonalDetailsSection: {
        //     type: Boolean,
        //     default: false,
        // },
        printableSections: {
            type: Boolean,
            default: false,
        },
        case_id: String,
        formStates: {
            type: Object,
            default: () => ({ loading: false, submitted: false }),
        },
        isCandidate: Boolean,
        gapErrorMsg: String,
        permissionEnforced: {
            type: String,
            default: ''
        },
        formAlreadySubmitted: {
            type: Boolean,
        },
        isAddReadOnly:{
            type: Boolean,
        },
        userData: {
            type: Object
        },
        entityId: {
            type: String
        },
        actionsNames: {
            type: Object,
            default: null
        },
        readOnlyMode:{
            type: Boolean,
        },
        /**
         * @param {string} fields_fe_id
         * @param {object} params
         * @return {Promise}
         */
        fetchFieldValueLogMethod: {
            type: Function,
        },
    },
    provide() {
      return {
        providedData: {
            entityId: this.entityId
        }
      }
    },
    data() {
        return {
            // sections_data: [],
            selectedSectionId: null,
            // isConsent: false,
            sections_to_save: {}, // * uncomment if need to check if section has been changed,
            current_copy: "",
            formBuilderId: "",
            requieredLinkedSection: {},
            saveDisabled:false,
            isDivVisible: false,
            selectedSectionIndex: null, // Variable to store the current index
            hideErrors: false,
            finalSubmit:false,
            allDuplicateFields: {},
            btnClicked: true ,     // true=>nextClicked false=>prevClicked
            sectionDivHeight: '',
            windowWidth: null,
            isError: false,
            isChromeOniPhone: "",
            chromeStyles: {
            },
            findErrors:{
                isSaved:false,
                currentIndex:null
            },
            isPoweredByVisible: false,
        }
    },
    validations() {
        const sections_data = {
            ...this.sections_data.map((el) => {
                return this.setValidations(el);
            }),
        };
        // TODO remove when custom sections are ready:
        // const personal_details = this.setPersonalValidations(this.getPersonalDetails);
        // if (personal_details !== null)
        //     return {sections_data, personal_details};
        return { sections_data };
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();

        this.updateStyles();
        window.addEventListener('resize', this.updateStyles);
        window.addEventListener('orientationchange', this.updateStyles);

        this.setPoweredBy();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    created() {
        window.addEventListener('orientationchange', this.calculateHeight);
        window.addEventListener('resize', this.calculateHeight);
        this.reloadFormBuilder();
        EventBus.$on("nextSection", this.nextSection);
        // TODO remove when custom sections are ready:
        // EventBus.$on("changePersonalFieldValue", (data) => {
        //     this.handleChangePersonalValue(data);
        // });

        // this.sections_data = this.data.sections;
        
        sections.utils.copyEntity = this.copyEntity;
        sections.utils.removeEntity = this.removeEntity;
        sections.utils.findGapPeriods = this.findGapPeriods;
        sections.utils.copyEntityWithoutSection = this.copyEntityWithoutSection
        sections.utils.saveGapModalBlock = this.saveGapModalBlock;
        sections.utils.fetchFieldValueLog = this.fetchFieldValueLogMethod;
        
        // Provide global variable for the current case id:
        this.caseId = this.case_id;
        this.initData();
    },
    beforeDestroy() {
        EventBus.$off("nextSection");
        removeLocalStorageItemsWithPrefix('gapTimeline_')

        window.removeEventListener('resize', this.updateStyles);
        window.removeEventListener('orientationchange', this.updateStyles);
        // TODO remove when custom sections are ready:
        // EventBus.$off("changePersonalFieldValue");
    },
    destroyed() {
        window.removeEventListener('orientationchange', this.calculateHeight);
        window.removeEventListener('resize', this.calculateHeight);
      },
    computed: {
        ...mapGetters(["getpoweredby" , "getLoginTenantDetails"]),

        getPoweredLogo()
        {
            if (this.getLoginTenantDetails) {
                return this.getLoginTenantDetails?.powered_by;
            } else if (this.getpoweredby !== null && this.getpoweredby !== undefined) {
                return this.getpoweredby;
            }
            return null; // Return null if neither condition is met
        },
        builderActionsNames() {
            const defaultActionsNames = {
                submit: 'Submit',
                preview: 'Preview'
            }   
            
            if (this.actionsNames) {
                for (const key in defaultActionsNames) {
                    if (this.actionsNames[key]) defaultActionsNames[key] = this.actionsNames[key];
                }
            }

            return defaultActionsNames;
        }, 
        dependencies() {
            return sections.dependencies;
        },
        sections_data: {
            get() {
                return sections.list;
            },
            set(val) {
                sections.list = val;
            },
        },
        personal_details: {
            get() {
                return sections.personal_details;
            },
            set(val) {
                sections.personal_details = val;
            },
        },
        gap_errors: {
            get() {
                return sections.gap_errors;
            },
            set(val) {
                // sections.gap_errors = val || {};
                mutations.handleGapErrors(val);
            },
        },
        gap_values: {
            get() {
                return sections.gap_values;
            },
            set(val) {
                sections.gap_values = val || {};
            },
        },
        caseId: {
            get() {
                return sections.case_id;
            },
            set(val) {
                sections.case_id = val || "";
            },
        },
        linked_blocks: {
            get() {
                return sections.linked_blocks;
            },
            set(val) {
                sections.linked_blocks = val || [];
            },
        },
        getFilteredSections(){
            return this.sections_data.length
                ? this.sections_data.filter((sec) => !sec.sectionHide).map((sec) => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, status: sec?.status, candidate_status: sec?.candidate_status ? sec?.candidate_status : sec?.status, analyst_status: sec?.analyst_status ? sec?.analyst_status : sec?.status, $v: sec.$v || {} })).sort((a, b) => b.section_order - a.section_order)
                : null;
        },
        getSections() {
            // if(this.isCandidate) { mutations.markDuplicateFields(); }
            return this.sections_data.map((sec) => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, status: sec?.status, candidate_status: sec?.candidate_status ? sec?.candidate_status : sec?.status, $v: sec.$v || {} , sectionHide: sec?.sectionHide })).sort((a, b) => b.section_order - a.section_order);
            // TODO remove when custom sections are ready:
            // return this.sectionDataExtended
            //     .map(sec => ({ id: sec.section_id, name: sec.section_name, order: sec.section_order, has_error: sec.has_error, custom: !!sec.custom, $v: sec.$v || {} }))
            //     .sort((a, b) => (b.section_order - a.section_order));
        },
        // TODO remove when custom sections are ready:
        // includes 'welcome' and 'personal details' sections:
        // sectionDataExtended() {
        //     const sections_data = this.sections_data.map((sec, idx) => ({
        //         ...sec,
        //         $v: this.$v.sections_data[idx],
        //     }));
        //     return [this.welcomeSection, this.personalDetailsSection, ...sections_data]
        //         .filter(section => section);
        // },
        getSelectedSection() {
            // return this.sections_data[0] NOTE(fix)
            return this.sections_data.length > 0 && this.selectedSectionId ? this.sections_data.find((sec) => sec.section_id === this.selectedSectionId) : {};
            // TODO remove when custom sections are ready:
            // return (this.sectionDataExtended.length > 0 && this.selectedSectionId) ? this.sectionDataExtended.find(sec => sec.section_id === this.selectedSectionId) : {}
        },
        getSelectedSectionIndex() {
            return this.sections_data.length > 0 && this.selectedSectionId ? this.sections_data.findIndex((sec) => sec.section_id === this.selectedSectionId) : 0;
        },
        // TODO remove when custom sections are ready:
        // includes 'welcome' and 'personal details' sections:
        // getSelectedSectionIndexExtended() {
        //     return (this.getSections.length > 0 && this.selectedSectionId) ? this.getSections.findIndex(sec => sec.id === this.selectedSectionId) : 0;
        // },
        errorFromServer() {
            // TODO
            return !!this.sections_data.find((el) => !!el.has_error);
        },
        getCurrentValidations() {
            return this.$v.sections_data[this.getSelectedSectionIndex];
        },
        // getPersonalValidations() {
        //     return this.$v.personal_details;
        // },
        getSectionsHeaderTitle() {
            var title = "Form preview";
            if (!this.previewMode) {
                title = "Sections";
            }
            return title;
        },
        consentField() {

            const visibleConsentField = this.getEntityByKeyListConsent(this.getSelectedSection.entities, {
                field_type: "Candidate Consent",
            });
            return visibleConsentField;
        },
        isConsent() {
            const values = this.consentField && this.consentField.length > 0
                ? this.consentField
                    .filter((el) => el.mandatory)
                    .map((el) => el.value)
                : [];
            const allConsentAgree = values?.length ? values.every(this.checkAllMandatoryConsent) : true;
            return allConsentAgree;
        },
        isConsentShown() {
            // if consent field is hidden
            if (!this.dependencies[this.consentField.pathString]?.hidden) return true;
        },
        // isConsentInCurrentSection() {
        //     return !!getEntityByKeyList(this.getSelectedSection.entities, {
        //         field_base_type: 'Consent',
        //     })
        // },
        isConsentPassed() {
            const passed = this.consentField && !this.consentField.isDuplicate ? this.isConsent : true;
            if(passed) {
                this.isError = false;
            }
            return passed
        },
        isSubmitDisabled() {
            const { submitted, loading } = this.formStates;
            return submitted || loading || this.$v.$invalid || !this.isConsentPassed || this.readOnlyMode;
        },
        getRefereeUserData() {
            return this.userData;
        },
        sectionEnvStatusProp() {
            return sectionStatusConfig.getStatusProperty(this.app);
        },
        disabledFormBuilder() {
            return sections.readOnlyMode;
        },
        isDirty() {
            return sections.isDirty;
        },
    },
    watch: {
        data: {
            immediate: true,
            handler(val) {
                this.initData(val);
            }
        } ,
        // update gap errors
        gapErrors(val) {
            this.gap_errors = {
                ...val,
                id: uuid.v4(),
            };
            // reset saved timelines:
            // this.gap_values = {}; // TODO
        },
        case_id(val) {
            this.caseId = val;
        },
        readOnlyMode: {
            handler(val) {
                mutations.setReadonlyMode(val);
            },
            immediate: true,
        },
    },
    methods: {
        initData(data) {
            if (!data) return;

            this.sections_data = (data.sections || []).map((el) => this.organizeFieldsData(el));
            this.personal_details = data.candidate || this.userInfo;
            this.linked_blocks = data.linked_blocks || [];

            this.gap_errors = {
                ...this.gapErrors,
                id: uuid.v4(),
            };

            this.generateGapErrors();

            // select active section
            if (this.sections_data.length) {
                let sectionIndex = this.sections_data.findIndex(x=> x.section_id == this.selectedSectionId);
                sectionIndex = sectionIndex != -1 ? sectionIndex : 0;
                // select depending on data from API or first by default^
                const currIndex = data.active_section || sectionIndex;
                // this.selectedSectionId = this.sections_data[currIndex].section_id
                this.selectedSectionId = this.getSections[currIndex].id;
                this.selectedSectionIndex = currIndex;
                // this.sections_data.map(section => {
                //    if (section.entities.length > 0) {
                //        section.entities = section.entities.map(entity =>  ({...entity, _id: this.generateRandomString(true)}) )
                //    }
                //    return section
                // })
            }
        },
        removeBlockMethod(p){
            this.$emit("removeBlockMethod", p);
        },
        removeFieldMethod(p) {
            this.$emit("removeFieldMethod", p);
        },
        firstBlockVisibleError() {
            let showFirstBlockError = false;
            if (this.getSelectedSection?.entities?.every(el => el.showFirstBlock)) {
                showFirstBlockError = false;
            } else {
                showFirstBlockError = true;
            }
            return showFirstBlockError;
        },
        updateStyles() {
            this.isChromeOniPhone = /CriOS/.test(navigator.userAgent) && /iPhone/.test(navigator.userAgent);
            this.chromeStyles.top = this.isChromeOniPhone ? '160px' : 'auto';
            // console.log("ischromeoniphone",this.isChromeOniPhone);
            // console.log("style top",this.chromeStyles.top);
          },
        blockhasErrors() {
            this.getSelectedSection?.entities?.forEach((secEnty) => {
                this.findErrors = {
                    isSaved: true,
                    currentIndex: this.getSelectedSectionIndex
                }
                secEnty?.entities?.map(elEnty => {
                    if(elEnty?.visible && !elEnty?.render_conditionally && elEnty?.mandatory) {
                        if (elEnty?.value == null || elEnty?.value == '' || elEnty?.value == undefined) {
                            elEnty.inValidBlock = true;
                        } else {
                            elEnty.inValidBlock = false;
                        }
                      }
                }); 
            });
        },
        async changeHiddenSection(){
            this.btnClicked ? await this.changeSection(1,true): await this.prevSection() ;
        },
        checkPermission,
        reloadFormBuilder() {
            this.formBuilderId = uuid.v4();
        },
        toggleDiv() {
            this.isDivVisible = !this.isDivVisible;
            this.calculateHeight();
        },
        calculateHeight() {
            const { height } = this.$el.getBoundingClientRect();
            const mediaQuery = window.matchMedia('(orientation: portrait)');
            if (mediaQuery.matches) {
                this.sectionDivHeight = (height-160)+'px'
            } else {
                this.sectionDivHeight = (height-160)+'px'
            }
        },
        onDragStart(event) {
            event.dataTransfer.setData('text/plain', ''); // Required for drag-and-drop
        },

        // Init 'value' prop for fields if not exist
        // path is used to make an unique field identificator
        organizeFieldsData(data, resetData = {}, initValueKey = "value", path = [], index = 0) {
            if (data.entities) {
                const additional = {};
                const newPath = [...path];
                if (data.form_entity_type === "BLOCK") {
                    additional.block_fe_id = data.block_fe_id || data.block_id;
                    additional.serial = data.serial ?? 1;
                    additional.order_serial = data.serial ?? 1;
                    newPath.push(additional.block_fe_id);
                } else if (data.section_id) newPath.push(data.section_id);
                return {
                    ...data,
                    ...additional,
                    ...resetData.block,
                    entities: data.entities
                        // TODO remove filter, added for skipping unknow types
                        .filter((entity) => {
                            return !(entity.form_entity_type === "FIELD" && !fieldTypes[entity.field_type.toLowerCase()] && !fieldTypes[entity.field_base_type.toLowerCase()]);
                        })
                        .map((el, index) => this.organizeFieldsData(el, resetData, initValueKey, newPath, index)),
                };
            } else if (data.form_entity_type === "FIELD") {
                const additional = {};
                additional.field_fe_id = data.field_fe_id || data.field_id;

                additional.field_serial = data.field_serial ?? 1;
                const newPath = [...path];
                newPath.push(additional.field_fe_id);
                const pathString = newPath.join("/");
                const pathParent = [...path];
                const pathParentString = path.join("/");
                if (data.render_conditionally && data.condition) {
                    this.$set(this.dependencies, pathString, { condition: data.condition });
                }
                if (data.options) {
                    additional.options = data.options
                        .map((opt) => ({
                            ...opt,
                            cascading_fields: opt.cascading_fields
                                .map((cf, cf_i) => this.organizeFieldsData(cf, resetData, initValueKey, path, cf_i)),
                        }))
                }
                // added index key for dependent fields to look up values
                return {
                    [initValueKey]: "",
                    ...data,
                    ...additional,
                    ...resetData.field,
                    path: newPath,
                    pathString,
                    pathParent,
                    pathParentString,
                    index
                };
            }
        },
        // Method for resettings entities' properies with saving links to entities:
        resetFieldsData(data, resetData = {}) {
            if (data.form_entity_type === "BLOCK") {
                return Object.assign(data, resetData.block, {
                    entities: data.entities.map((el) => this.resetFieldsData(el, resetData)),
                });
            } else if (data.form_entity_type === "FIELD") {
                return Object.assign(data, resetData.field);
            } else {
                // sections
                return Object.assign(data, {
                    entities: data.entities.map((el) => this.resetFieldsData(el, resetData)),
                });
            }
        },
        // Set valdiation for fields, 
        setValidations(data, values = {}) {
            if (data.entities) {
                return {
                    entities: { ...data.entities.map((el) => this.setValidations(el, values)) },
                };
            } else if (data.form_entity_type === "FIELD") {
                const fieldType = fieldTypes[data.field_type.toLowerCase()] || fieldTypes[data.field_base_type.toLowerCase()];
                const result = {
                    ...values,
                    ...fieldType.validators,
                };
                result.requiredIf = helpers.withParams(
                    { $message: `This field is required` },
                    requiredIf(() => {
                        return data.mandatory && !this.dependencies[data.pathString]?.hidden && !fieldType.skipValidation && data.visible && !data.isDuplicate;
                    })
                );
                if (data.field_type.toUpperCase()==='CANDIDATE PHONE' && data.mandatory && !data.isDuplicate) data.field_validation_regex = /^\+\d{1,5}\d{8,}$/
                if (data.field_validation_regex) result.regex = helpers.withParams({ $message: `Invalid field value` }, customValidators.regex(data.field_validation_regex));

                const resultValidations = {
                    value: result
                };

                // validations for cascading fields:
                const cascadingFields = getCascadingFields(data);
                if (cascadingFields) {
                    resultValidations.options = {
                        ...data.options.map((option) => {
                            const isSelected = option.option_value === data.value
                            return {
                                // validate only current visible cascading fields:
                                cascading_fields: isSelected && !data.use_value_from
                                    ? { ...option.cascading_fields.map((el) => this.setValidations(el, values)) }
                                    : {}
                            }
                        })
                    }
                }

                return resultValidations;
            }
        },
        checkIfCascaded(data) {
            return !!data.options?.some((el) => el.cascading_fields?.length);
        },
        // generateRandomString,
        setCurrentSection(section /* , next = true */) {
            // we don't check consent when go back
            // if (!this.isConsentPassed && next && !this.previewMode) return;
            // TODO remove when custom sections are ready:
            // we can switch between custom sections without consent and validation
            // &&
            // !this.getSelectedSection.custom &&
            // !section.custom
            if (section && this.selectedSectionId !== section.id) this.selectedSectionId = section.id;
        },
        // TODO remove, no more need
        getEntityPath(source, path, resultGenerator, i = 0, result = []) {
            const getEntityIndex = (entities, currPath) => {
                return entities.findIndex((el) => el[currPath.field] === currPath.value).toString();
            };
            const currIndex = getEntityIndex(source, path[i]);
            let currResult = [...result, currIndex];
            // if it was the last el generate result depending on case
            if (i === path.length - 1) return resultGenerator(currResult);
            else return this.getEntityPath(source[currIndex].entities, path, resultGenerator, i + 1, currResult);
        },
        // handleChangePersonalValue(data) {
        //     this.$set(this.personal_details, data.id, data.value);
        // },
        // used for entity copying:
        resetFields(data, values = {}, reset_id = true, path = []) {
            // TODO use link to entity object instead of generated 'path' property
            if (data.entities) {
                const newPath = [...path];
                // TODO handle other entity types if will be
                if (data.form_entity_type === "BLOCK") {
                    // new entitie's id:
                    data.block_fe_id = uuid.v4();
                    newPath.push(data.block_fe_id);
                }
                data.entities = data.entities.map((el) => this.resetFields(el, values, true, newPath));
                return data;
            } else {
                const result = { ...data, ...values };
                if (reset_id) {
                    result.field_fe_id = uuid.v4();
                }
                // set one copy_id for this set of copies to
                result.copy_id = this.current_copy;
                // reset path idetificator:
                const newPath = [...path];
                newPath.push(result.field_fe_id);
                const resetPath = [...data.path];
                resetPath.splice(-newPath.length, newPath.length, ...newPath);
                const pathString = resetPath.join("/");
                const pathParent = [...resetPath];
                pathParent.splice(-1, 1);
                result.path = resetPath;
                result.pathString = pathString;
                result.pathParent = pathParent;
                result.pathParentString = pathParent.join("/");
                // set new dependency:
                if (data.render_conditionally && data.condition) {
                    this.$set(this.dependencies, result.pathString, { condition: data.condition });
                }
                return result;
            }
        },

        copyEntity({ entity, list = [], values = {}, nextBlocks = {}, previous = entity, clone = false, block_clone_id=null }, addElemToLast = false, saveAndNext = false) {
            // * previous - we can manage where to insert new entity (after which element)

            this.current_copy = uuid.v4();
            // // note copies count of the current entity
            // if (!original.copy_count) original.copy_count = 0;
            // original.copy_count++;

            // alternative copy count calculdating:
            // const copyCount = originalSource.filter(el => el.original_entity === entityParams.value).length;

            const copy = cloneDeep(entity);

            // new values for fields:
            const resetValues = { value: null, ...values };
            // new entitie's id:
            // const uuidId = uuid.v4() // is reset in resetField method

            var copy_key_name = "";
            if (entity.form_entity_type === "BLOCK") {
                copy_key_name = "block_fe_id";
            }
            if (entity.form_entity_type === "FIELD") {
                copy_key_name = "field_fe_id";
            }

            // reset all fields' values:
            const newEntity = {
                ...this.resetFields(copy, resetValues, true),
                is_copy: true,
                // set new id depending on the current entity copies count
                // [entityParams.field]: uuidId,
                // [entityParams.field]: `${entityParams.value}_${original.copy_count}`,
                // original_entity: original.original_entity || entityParams.value,
                // [copy_key_name]:uuidId
                // ({...el,value: values[el.field_id] || "xxxx" })
                serial: copy.serial + 1, // TODO , this should be incremental
                order_serial:saveAndNext?copy.serial:copy.serial+1
            };
            if (clone) {
                if (newEntity.form_entity_type === "BLOCK") newEntity.entities = newEntity.entities.map((el) => this.resetFields(el, { value: values[el.field_id || el.field_fe_id] }, true));
            }

             //generating field_id for save next clone entity(linking) //TODO
             if(nextBlocks && saveAndNext) {
                newEntity.entities = newEntity.entities.map((el) => {
                    nextBlocks.entities.map((nextBlk) => {
                        if(nextBlk.field_internal_name == el.field_internal_name) {
                            el.field_id = nextBlk.field_id
                        }
                    })
                    return el; 
                })
            }
            // newEntity[copy_key_name] = uuid.v4() // ? duplicate

            // disable mandatory only if we copy FIELD entity:
            if (entity.form_entity_type === "FIELD") resetValues.mandatory = false; // ! move it newEntity creating to enable

            // newEntity['xx'] = uuid.v4()
            // newEntity[copy_key_name] = uuidId // is reset in resetField method

            //mutate original, TODO, fix this later , copy should not mutate.
            // original[copy_key_name] = original.original_entity || entityParams.value,
            // except property copy_count
            // delete newEntity.copy_count;
            // add a new entity to the source list
            // ? add just after the original or after the last copy?

            const newIndex = addElemToLast ? list.length : list.findIndex((el) => el[copy_key_name] === previous[copy_key_name]) + 1;
            this.current_copy = "";
            if(saveAndNext) {
                list.splice(entity.index, 0, newEntity);
                Object.assign(newEntity, { is_LinkedCopy: true });
                Object.assign(newEntity, {block_id: nextBlocks.block_id});
                Object.assign(newEntity, {order_serial: entity.order_serial ? entity.order_serial : 1})
                Object.assign(newEntity, {serial: newEntity.order_serial})
            } else {
                list.splice(newIndex, 0, newEntity);
                Object.assign(newEntity, {order_serial: list instanceof Array ? list.length : 1})
                Object.assign(newEntity, {serial: newEntity.order_serial})
            }
            if(block_clone_id) Object.assign(newEntity, {block_clone_id : block_clone_id});
            return newEntity;
        },

        copyEntityWithoutSection( entity, list = [], values = {}, clone=false, saveAndNext= false) {
            this.current_copy = uuid.v4();
            const copy = cloneDeep(entity);
            const resetValues = { value: null, ...values }

            // reset all fields' values:
            const newEntity = {
                ...this.resetFields(copy, resetValues, true),
                is_copy: true,
                order_serial:saveAndNext?copy.serial:copy.serial+1
            };

            if (clone) {
                if (newEntity.form_entity_type === "BLOCK") newEntity.entities = newEntity.entities.map((el) => this.resetFields(el, { value: values[el.field_id || el.field_fe_id] }, true));
            }

            // disable mandatory only if we copy FIELD entity:
            if (entity.form_entity_type === "FIELD") resetValues.mandatory = false; // ! move it newEntity creating to enable

            this.current_copy = "";
            Object.assign(newEntity, {order_serial: list instanceof Array ? list.length : 1})
            Object.assign(newEntity, {serial: newEntity.order_serial})
            return newEntity;
        },

        linkingCopyEntity({list, nextSection}) {
            const nextList = cloneDeep(list);
            let requiredList;
            const originalBlock = nextSection.entities.find((el) => el.block_id == el.block_fe_id && el.form_entity_type == 'BLOCK');
            if(nextList) {
                requiredList = nextList.filter((blocks) => {
                    if(blocks.form_entity_type == 'BLOCK') {
                        if(blocks.block_id == blocks.block_fe_id) {
                          blocks.block_fe_id = originalBlock.block_id 
                          blocks.block_id = originalBlock.block_id
                          blocks.use_value_from = originalBlock.use_value_from 
                        } else {
                            blocks.block_id = originalBlock.block_id
                            blocks.is_LinkedCopy = true;
                        }
                        //generating field_id for save next clone entity(linking) //TODO
                        blocks.entities = blocks.entities?.map((el) => {
                            originalBlock.entities?.map((nextBlk) => {
                                if(nextBlk.field_internal_name == el.field_internal_name) {
                                    el.field_id = nextBlk.field_id
                                }
                            })
                            return el; 
                        })
                        return blocks
                    } else {
                        return;
                    }
                })
            }
            nextSection.entities = nextSection.entities.filter((ent) => !this.isLinked(ent))
            nextSection.entities = [...nextSection.entities, ...requiredList];
            return nextSection;
        },

        saveGapModalBlock({list, currentBlock, requiredIndex}) {
            list.splice(requiredIndex,1);
            list.splice(requiredIndex,0,currentBlock);
        },

        // reOrderBlocks() {},
        resetCopies() {
            const resetData = {
                reset: true,
                block: { is_copy: false },
                field: { is_copy: false },
            };
            this.sections_data = this.sections_data.map((el) => this.resetFieldsData(el, resetData));
        },
        async removeEntity(data) {
            const entity_id_prop = {
                BLOCK: "block_fe_id",
                FIELD: "field_fe_id",
            };
            if (data.is_saved) {
                // const apiMethods = {
                //     BLOCK: this.removeBlockMethod,
                //     FIELD: this.removeFieldMethod,
                // };
                try {
                    // await apiMethods[data.form_entity_type](data.entity[entity_id_prop[data.form_entity_type]]);
                    let expression = data.form_entity_type;
                    let id = data.entity[entity_id_prop[data.form_entity_type]]
                    switch(expression) {
                        case 'BLOCK': {
                            await this.removeBlockMethod(id);
                            break;
                        }
                        case "FIELD": {
                            this.removeFieldMethod(id);
                            break;
                        }
                    }
                } catch (error) {
                    console.error("Failed to remove entity", error);
                    throw error;
                }
            }
            const removeIndex = data.list.findIndex((el) => el[entity_id_prop[data.form_entity_type]] === data.entity[entity_id_prop[data.form_entity_type]]);
            data.list.splice(removeIndex, 1);
            //maintaing order for linking block
            data.list = data.list.map((blocks, blockIndex) => {
                blocks.order_serial = blockIndex + 1;
                return blocks
            })
        },
        linkedBlockGroup(CurrentDatablockData) {
            // check block_id to include copies in calculations:
            return (
                this.linked_blocks.find((group) =>
                  group.includes(CurrentDatablockData.block_id)
                ) || null
            );
        },
        isLinked(CurrentDatablockData) {
            if (!this.linkedBlockGroup(CurrentDatablockData)) return null;
            // check block_id to include copies in calculations:
            return this.linkedBlockGroup(CurrentDatablockData).some((el) => el === CurrentDatablockData.block_id);
        },
        prevSection() {
            this.btnClicked = false;
            this.saveDisabled=false 
            const check = !this.previewMode;
            let back = true;
            this.changeSection(-1, check, back);
            this.formStates.loading = false;
        },
        async nextSection() {
            this.blockhasErrors();
            this.firstBlockVisibleError();
            this.finalSubmit=true;
            this.saveDisabled = true;
            this.btnClicked = true;
            this.$refs?.dynamicFormBuilder.scrollToGapOnSave();
            const check = !this.previewMode;
            await this.changeSection(1, check);
            this.finalSubmit=false;
        },
        fetchLinkedSection() {
            for (const section of this.sections_data) {
                for (const entity of section.entities) {
                  if (entity.form_entity_type == "BLOCK" && this.isLinked(entity)) {
                      this.requieredLinkedSection = section;
                    return 
                  }
                }
            }
        },
        copyEntityDuringSave(newSection) {
            if (!newSection) return;
            this.fetchLinkedSection();
            if(!this.requieredLinkedSection) return;
            let nextSection = this.sections_data.find((sec) => sec.section_name == newSection.name)
            if(this.requieredLinkedSection.section_id == nextSection.section_id) return;
            let requieredLinkekBlockNames = this.requieredLinkedSection.entities?.map(blk => blk?.block_name)
            let cloneRequired = nextSection.entities.some((newBlock) => this.isLinked(newBlock) && requieredLinkekBlockNames?.includes(newBlock.block_name));
            if(!cloneRequired) return;
            this.linkingCopyEntity({
                list: this.requieredLinkedSection.entities,
                nextSection: nextSection
            })
            const entities_of_current=this.requieredLinkedSection.entities.map(item=>item.order_serial);

            const itemPositions = {};
            for (const [index, order_serial] of entities_of_current.entries()) {
            itemPositions[order_serial] = index;  
            }
            nextSection.entities.sort((a, b) => itemPositions[a.order_serial] - itemPositions[b.order_serial]);
        },
        /**
         * 
         * @param {Object} section Pass the section you eant to move to
         *  it must be COMPLETED, if you want to switch to it.
         */
        async changeSectionHandler(section) {
            if (this.isSectionSelectable(section)) {
                let end, start;
                start = this.sections_data.findIndex((secs) => secs.section_id === this.getSelectedSection.section_id);
                end = this.sections_data.findIndex((secs) => secs.section_id === section.id);
                const check = !this.previewMode;
                const back = true;
                this.hideErrors = true;
                this.isDivVisible = false;
                await this.changeSection(end - start, check, back);
            }
        },
        isSectionSelectable(section) {
            const status = this.getSectionStatus(section);
            const sectionIndex = this.sections_data.findIndex((item) => item.section_id === section.section_id);
            return !!(status === "COMPLETED" || status == "ERROR" || status == "PARTIALLY_COMPLETED" || sectionIndex === 0);
        },
        /**
         * 
         * @param {String} section_id : Switch to the section fo the form with it's section_id
         */
        async switchSection(section_id) {
            let end, start;
            start = this.sections_data.findIndex((secs) => secs.section_id === this.getSelectedSection.section_id);
            end = this.sections_data.findIndex((secs) => secs.section_id === section_id);
            const check = !this.previewMode;
            await this.changeSection(end - start, check);
        },
        async saveSection() {
            // if (this.saveSectionMethod && this.sections_to_save[this.getSelectedSection.section_id]) { // * uncomment if need to check if section has been changed
            if (this.saveSectionMethod) {   
                try {
                    await this.saveSectionMethod({
                        section_id: this.getSelectedSection.section_id,
                        data: this.getSelectedSection,
                    });
                    mutations.setIsDirty(false);
                    this.saveDisabled = false;
                    // this.$set(this.sections_to_save, this.getSelectedSection.section_id, false); // * uncomment if need to check if section has been changed
                } catch (error) {
                    this.saveDisabled = false;
                    this.finalSubmit = false;
                    const resp = error?.response?.data;
                    const errorData = resp?.detail?.error;
                    const errorCode = errorData?.code;
                    if (formSavedErrorCodes.includes(errorCode)) this.resetCopies();

                    const newSectionsData = resp?.data?.sections;
                    if (newSectionsData) {
                        this.sections_data.forEach((el, i) => {
                            const sectionToReset = getEntityByKeyList(newSectionsData, { section_id: el.section_id });
                            if (sectionToReset) {
                                this.$set(this.sections_data, i, this.organizeFieldsData(sectionToReset));
                            }
                        });
                        this.reloadFormBuilder();
                    }

                    throw new Error(error);
                }
            }
        },
        fillAllDuplicateFields(){
            const duplicateFields = {...this.allDuplicateFields};

            this.getSelectedSection.entities.forEach((bl) => {
                if (bl.form_entity_type === "BLOCK") {
                    // bl.entities.forEach((field) => {
                    //     if (field.is_hide_duplicate_field) {
                    //         if (Object.keys(duplicateFields).includes(field.label) && field.isDuplicate) {
                    //             field.value = duplicateFields[field.label];
                    //         }
                    //         else if(!field.isDuplicate){
                    //             duplicateFields[field.label] = field.value
                    //         }
                    //     }
                        
                    // })
                }
                if (bl.form_entity_type === "FIELD") {
                    if (bl.is_hide_duplicate_field ) {
                        if (Object.keys(duplicateFields).includes(bl.label) && bl.isDuplicate) {
                            bl.value = duplicateFields[bl.label];
                        }
                        else if(!bl.isDuplicate){
                            duplicateFields[bl.label] = bl.value;
                        }
                    }
                    
                }
            })
            this.allDuplicateFields = duplicateFields;
        },
        async changeSection(delta, check = false, back) {
            try {
                if (check && !back && !this.isAddReadOnly) {
                    // TODO remove when custom sections are ready:
                    // // custom sections:
                    // if (this.changeCustomSection[this.getSelectedSection.section_id]) {
                    //     const goNext = await this.changeCustomSection[this.getSelectedSection.section_id]();
                    //     if (!goNext) return;
                    // // other sections
                    // } else {
                    this.btnClicked = true
                    this.saveDisabled = true;
                    this.getCurrentValidations.$touch();
                    this.scrollToInvalidField()
                    if (delta > 0 && !this.isConsentPassed) {
                        this.finalSubmit=false;
                        this.saveDisabled=false 
                        return
                    }
                    if (delta < 0 && !this.isConsentPassed) {
                        this.consentField.value = "disagree";
                    }
                    // if form is still invalid after consent was set the sections changing stops:
                    if (this.getCurrentValidations.$invalid) {
                        this.saveDisabled = false;
                        this.finalSubmit=false;
                        mutations.setSectionStatus(this.selectedSectionId, "ERROR", this.sectionEnvStatusProp);
                        logValidationErrors(this.getCurrentValidations.entities);
                        return;
                    }
                    // if(this.isCandidate){this.fillAllDuplicateFields();}
                    await this.saveSection();
                    // }
                }
                this.saveDisabled = false;
                const newIndex = this.getSelectedSectionIndex + delta;
                
                if (newIndex == this.sections_data.length) return;

                const newSection = this.getSections[newIndex];
                
                // TODO remove when custom sections are ready:
                // const newSection = this.getSections[this.getSelectedSectionIndexExtended + delta];

                // check consent in case user goes forward:
                if (!this.isConsentPassed && delta > 0 && !this.previewMode) return;
                if(!back){
                    mutations.setSectionStatus(this.selectedSectionId, "COMPLETED", this.sectionEnvStatusProp);
                }
                if(delta > 0) this.copyEntityDuringSave(newSection);
                this.setCurrentSection(newSection /* , delta > 0 */);
                this.resetCopies();
            } catch (error) {
                this.saveDisabled = false;
                this.finalSubmit = false;
                throw new Error(error)
            }
        },
        async saveForm() {
            try {
                this.finalSubmit=true;
                const check = !this.previewMode;    
                this.$refs?.dynamicFormBuilder.scrollToGapOnSave();
                await this.changeSection(1, check);
    
                if (this.$v.$invalid || !this.isConsentPassed) {
                    logValidationErrors(this.$v.sections_data);
                    this.$toast.error("Please Fill mandatory fields");
                    this.finalSubmit=false;
                    return;
                }

                this.sumbitForm();
                this.$v.$reset();
            } catch (error) {
                console.warn("save form error", error);
            } finally {
                this.finalSubmit = false;
            }
        },
        sumbitForm() {
            this.$emit("submit", {
                section_id: this.getSelectedSection.section_id,
                data: this.getSelectedSection,
            });
        },
        printSection() {
            const form_builder = this.$refs?.dynamicFormBuilder;
            const non_field = Object.keys(form_builder?.$refs)?.filter((el) => !el.includes("form-field"));
            let consent = form_builder?.$refs?.["form-field-consent"];

            for (const item in non_field) {
                consent = {...consent, ...form_builder?.$refs[non_field?.[item]]?.[0]?.$refs?.["form-field-consent"] };
                form_builder?.$refs[non_field?.[item]]?.[0]?.$el.classList.remove("bg-gray-100")
            }
            for (const field in consent) {
                consent[field]?.$refs["consent-field-block"]?.classList.remove("max-h-36");
                consent[field]?.$refs["consent-field-block"]?.classList.remove("overflow-auto");
                consent[field]?.$refs["consent-field-block"]?.classList.remove("scroll-bar");
            }
            window.print();
            for (const field in consent) {
                consent[field]?.$refs["consent-field-block"]?.classList.add("max-h-36");
                consent[field]?.$refs["consent-field-block"]?.classList.add("overflow-auto");
                consent[field]?.$refs["consent-field-block"]?.classList.add("scroll-bar");
            }
            for (const item in non_field) {
                consent = {...consent, ...form_builder?.$refs[non_field?.[item]]?.[0]?.$refs?.["form-field-consent"] };
                form_builder?.$refs[non_field?.[item]]?.[0]?.$el.classList.add("bg-gray-100")
            }
        },
        showPreviewModal() {
            this.$refs.preview_form_modal.showModal();
        },

        /***
         * set section status of form sections
         * initially it is returned via backend
         * then calculated to show status icons at the sidebar
         * @params {section} current section
         * 
         */
        sectionStatus(section) {
            const status = this.getSectionStatus(section);
            let error = !!section.has_error || section.$v.$error || status === "ERROR";
            // to do complete with api changes
            
            let completed = status === "COMPLETED"
            let current =  this.app === 'CreateCase' ? (section.status === "PENDING" || section.status === "COMPLETED" || status === "PARTIALLY_COMPLETED")&& section.id === this.selectedSectionId && !completed && !error : !error && !completed ;
            let pending = ((!error && !current && !completed) || status === "PENDING") && !current;
            return { error, completed, current, pending };
        },
        getSectionStatus(section) {
            const mainStatus = section.status;
            const envStatus = section[this.sectionEnvStatusProp];
            return  this.app === 'CreateCase' ? envStatus : mainStatus;
        },
        generateGapErrors() {
            //removed initial gap error from true to false
            let gapErrors = this.gap_errors?.gaps || [];
            let userInputDates = {};
            let now = new Date();
            const fetchGapErrors = (entity, currentBlock) => {
                if (entity.form_entity_type === "FIELD" && entity.gap_validation !== null) {
                    const { block_id, block_name, block_fe_id } = currentBlock;
                    const { field_id, order, gap_validation } = entity;
                    if(entity.value) {
                        if(entity.field_type === 'Date Range (Timeline)') {
                            const userEntity = currentBlock.entities
                            .filter((ent) => ent.value && ent.field_type === "Date Range (Timeline)" && ent.gap_validation)
                            .map(ent => {
                                // let isSafari = window.safari !== undefined;
                                // if(isSafari){
                                    const {from ,to } =JSON.parse(ent.value);
                                    const startDate = from?.split(" ")[0]; 
                                    const endDate =  to?.split(" ")[0]; 
                                    return({start: new Date(startDate), end: new Date(endDate), 
                                        block: currentBlock, field: ent})
                                // }else{
                                //     return({start: new Date(JSON.parse(ent.value).from), end: new Date(JSON.parse(ent.value).to), 
                                //         block: currentBlock, field: ent})
                                // }
                             });
                            if(block_id in userInputDates) {
                                userInputDates[block_id].push(...userEntity)
                            } else {
                                userInputDates[block_id] = userEntity
                            }
                        }
                    } else {
                        let timelineObj = {};
                        if (gap_validation.relative) {
                            let gapEndDate;
                            let gapStartDate
                            if (gap_validation.till_present) {
                                gapEndDate = now;
                            } else {
                                gapEndDate = getFieldById(this.sections_data, gap_validation.end_date).value || ""
                            }
                            gapStartDate = getFieldById(this.sections_data, gap_validation.start_date).value || ''
                            if(gapStartDate && gapEndDate) {
                                timelineObj = {
                                    field_id,
                                    start: formatDate(gapStartDate),
                                    end: formatDate(gapEndDate),
                                    current: false,
                                    block_fe_id,
                                    invalid_gap: true,
                                };
                            }
                        } else {
                            timelineObj = {
                                field_id,
                                start: formateDateToString(subtractMonths(now, gap_validation.gap_history)),
                                end: formateDateToString(now),
                                current: false,
                                block_fe_id,
                                invalid_gap: true,
                            };
                        }
                        
                        const gapField = {
                            block_id,
                            block_name,
                            field_id,
                            field_order: order,
                            timeline: [timelineObj],
                        };
                        gapErrors.push(gapField);
                    }
                    return;
                } else if (entity.form_entity_type === "BLOCK") {
                    entity.entities.forEach((ent) => fetchGapErrors(ent, entity));
                }
            };

            
            for (const section of this.sections_data) {
                for (const entities of section.entities) {
                    fetchGapErrors(entities);
                }
            }
            
            if(!isObjectEmpty(userInputDates)) {
                Object.values(userInputDates).forEach((value) => {
                    const { block_id, block_name } = value[0].block;
                    const { field_id } = value[0].field;
                    const gapFound = this.findGapPeriods(value);
                    const invalidGaps = gapFound.filter((gap) => gap.invalid_gap)
                    const validGaps = gapFound.filter((gap) => !gap.invalid_gap).map((gap) => {
                        const gapValue = JSON.parse(gap.field.value);
                        const gapObj = {
                            field_id: gap.field.field_id,
                            start: gapValue.from,
                            end: gapValue.to,
                            current: false,
                            block_fe_id: gap.block.block_fe_id,
                            invalid_gap: false
                        }
                        return gapObj;
                    })
                    const gapField = {
                        block_id,
                        block_name,
                        field_id,
                        timeline: [...invalidGaps, ...validGaps]
                    };
                    gapErrors.push(gapField);
                })
            }

            this.gap_errors = {
                gaps: gapErrors,
                gapErrorsView: true,
                message: "Please provide relevant information for below mentioned gaps.",
                id: uuid.v4(),
            }
        },
        /**
         * 
         * @param {*} dateList array of date objects 
         * @returns an array of gap objects with gap timeline of each block
         *        with timeline of proper blocks (valid gaps) and missed gap
         */
        findGapPeriods(dateList = []) {
            if(dateList.length <= 0 ) {
              return []
            }
            const sortedDates = dateList.sort((a, b) => a.start - b.start);

            const computeDays = (date, days, add = true) => {
                let result = new Date(date);
                if (add) {
                    result.setDate(result.getDate() + days);
                } else {
                    result.setDate(result.getDate() - days);
                }
                return result;
            };
            const gapHistory = sortedDates[0].field.gap_validation.gap_history;
            // if valid gap is  zero we default it to 1 for easier calculations
            const validGap = sortedDates[0].field.gap_validation.valid_gap || 1;
      
            const startDate = computeDays(new Date(subtractMonths(new Date(), gapHistory)), 0, false);
            const endDate = new Date();
      
            let timeline = [
                {
                    ...sortedDates[0],
                    start: startDate,
                    end: startDate,
                },
                ...sortedDates,
                {
                  ...sortedDates[0],
                    start: endDate,
                    end: endDate,
                },
            ];
            timeline.forEach((item) => {
                item.effStart = item.start.getTime() < startDate.getTime() ? startDate : item.start;
                item.effEnd = item.end.getTime() > endDate.getTime() ? endDate : item.end;
            });
            const gapTimeline = [];
            for (let i = 0; i < timeline.length - 1; i++) {
                const currentDateRange = timeline[i];
                const nextDateRange = timeline[i + 1];
      
                const endDate = currentDateRange.effEnd;
                const nextStartDate = nextDateRange.effStart;
      
                const timeDifference = new Date(nextStartDate).getTime() - new Date(endDate).getTime();
                const gapInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
                gapTimeline.push({ field: { ...timeline[i].field, value: JSON.stringify({ from: formatDate(currentDateRange.start), to: formatDate(currentDateRange.end), current: false }) }, block: timeline[i].block, invalid_gap: false });
      
                if (gapInDays > validGap) {
                    gapTimeline.push({
                        start: formatDate(computeDays(new Date(endDate), 1)),
                        end: formatDate(computeDays(new Date(nextStartDate), 0, false)),
                        invalid_gap: true,
                        current: false,
                        block_id: timeline[i].block.block_id,
                    });
                }
            }
      
            return gapTimeline.slice(1, gapTimeline.length);
        },
        scrollToInvalidField(){
            this.$nextTick(()=>{
                document.querySelectorAll('.form-field--invalid')[0]?.scrollIntoView({ behavior: "smooth" })
            })
        },
        handleWindowResize() {
           this.getWindowSize();
         },
        getWindowSize() {
           this.windowWidth = window.innerWidth;
        },
        getEntityByKeyListConsent(list, keys) {
            this.modelConsentList = [];

            if (list) {
                for (const item of list) {
                    const isEqual = isEquel(item, keys);
                    const isConsentShown = !this.dependencies[item.pathString]?.hidden;
                    if(isEqual && isConsentShown) {
                        this.modelConsentList.push(item);
                    }
                    if(item.entities?.length) {
                        this.getEntityByKeyListConsent(item.entities, keys);
                    }
                    const cascadingFields = getCascadingFields(item);
                    if (cascadingFields) {
                        this.getEntityByKeyListConsent(cascadingFields, keys);
                    }
                }
            }
            return this.modelConsentList;
        },
        checkAllMandatoryConsent(val) {
            if (val && isJson(val) && JSON.parse(val).value === "agree") {
                return true;
            } else if (val && val.value === "agree") {
                return true;
            }

            return false;
        },
        setPoweredBy() {
            const themeObj = localStorage.getItem("themeObj");
            if (!themeObj) return;
            this.isPoweredByVisible = JSON.parse(themeObj)?.powered_by;
        },
    },
};
